<template>

    <div class="gdpr" v-if="!isGdprSet && messages">

      <b-modal id="modal-gdpr" :title="messages['fh.gdpr.headline']" v-model="modalShow">

        <p class="my-4" v-html="messages['fh.gdpr.body']"></p>

        <div class="row">
          <div class="col text-right">
            <button
              type="button"
              class="button secondary"
              @click="collapseVisible = !collapseVisible">
              {{ messages['fh.gdpr.einstellungen'] }}
              <b-icon icon="plus-lg" class="ml-3" v-if="!collapseVisible"></b-icon>
              <b-icon icon="x" class="ml-3" v-else></b-icon>
            </button>
          </div>
        </div>

        <b-collapse id="gdpr-collapse" v-model="collapseVisible" class="mt-2">

          <div class="accordion" role="tablist">

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block
                          v-b-toggle.accordion-1
                          variant="default"
                          style="text-align: left;text-decoration: underline;">

                  <div class="row">
                    <div class="col-md-10">
                      {{ messages['fh.gdpr.erforderlich'] }}
                    </div>
                    <div class="col-md-1">
                      <b-form-checkbox
                        id="checkbox-erforderlich"
                        v-model="erforderlich"
                        :disabled="true"
                        name="checkbox-erforderlich">
                      </b-form-checkbox>
                    </div>
                    <div class="col-md-1 text-right">
                      <span class="when-open">
                        <b-icon icon="x"></b-icon>
                      </span>
                      <span class="when-closed">
                        <b-icon icon="plus-lg"></b-icon>
                      </span>
                    </div>
                  </div>

                </b-button>
              </b-card-header>
              <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                <b-card-body>

                  <b-card-text>{{ messages['fh.gdpr.erforderlich.desc'] }}</b-card-text>
                  <b-card-text class="mt-3">
                    <b-table-simple hover small caption-top responsive>
                      <b-thead head-variant="dark">
                        <b-tr>
                          <b-th>{{ messages['fh.gdpr.name'] }}</b-th>
                          <b-th>{{ messages['fh.gdpr.zweck'] }}</b-th>
                          <b-th>{{ messages['fh.gdpr.ablauf'] }}</b-th>
                          <b-th>{{ messages['fh.gdpr.typ'] }}</b-th>
                          <b-th>{{ messages['fh.gdpr.anbieter'] }}</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-td>{{ messages['fh.gdpr.cookieConsent'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.cookieConsent.desc'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.ablauf1'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.typ.html'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.anbieter.website'] }}</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-card-text>

                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2
                          variant="default"
                          style="text-align: left;text-decoration: underline;">

                  <div class="row">
                    <div class="col-md-10">
                      {{ messages['fh.gdpr.webstatistiken'] }}
                    </div>
                    <div class="col-md-1">
                      <b-form-checkbox
                        id="checkbox-statistik"
                        v-model="statistik"
                        name="checkbox-statistik"
                        value="true"
                        unchecked-value="false">
                      </b-form-checkbox>
                    </div>
                    <div class="col-md-1 text-right">
                      <span class="when-open">
                        <b-icon icon="x"></b-icon>
                      </span>
                      <span class="when-closed">
                        <b-icon icon="plus-lg"></b-icon>
                      </span>
                    </div>
                  </div>

                </b-button>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body>

                  <b-card-text>{{ messages['fh.gdpr.webstatistiken.desc'] }}</b-card-text>
                  <b-card-text class="mt-3">
                    <b-table-simple hover small caption-top responsive>
                      <b-thead head-variant="dark">
                        <b-tr>
                          <b-th>{{ messages['fh.gdpr.name'] }}</b-th>
                          <b-th>{{ messages['fh.gdpr.zweck'] }}</b-th>
                          <b-th>{{ messages['fh.gdpr.ablauf'] }}</b-th>
                          <b-th>{{ messages['fh.gdpr.typ'] }}</b-th>
                          <b-th>{{ messages['fh.gdpr.anbieter'] }}</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-td>_ga</b-td>
                          <b-td>{{ messages['fh.gdpr.ga.zweck'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.ga.ablauf'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.typ.html'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.ga.anbieter'] }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td>_gid</b-td>
                          <b-td>{{ messages['fh.gdpr.ga.zweck'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.gid.ablauf'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.typ.html'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.ga.anbieter'] }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td>_dc_gtm_UA-7497985-1</b-td>
                          <b-td>{{ messages['fh.gdpr.dc_gtm.zweck'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.ga.ablauf'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.typ.html'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.ga.anbieter'] }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td>_gali</b-td>
                          <b-td>{{ messages['fh.gdpr.gali.zweck'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.gali.ablauf'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.typ.html'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.ga.anbieter'] }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td>_meta_pixel</b-td>
                          <b-td>{{ messages['fh.gdpr.meta_pixel.zweck'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.meta_pixel.ablauf'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.typ.html'] }}</b-td>
                          <b-td>{{ messages['fh.gdpr.meta_pixel.anbieter'] }}</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-card-text>

                </b-card-body>
              </b-collapse>
            </b-card>

          </div>

        </b-collapse>

        <template #modal-footer>
          <div class="w-100">

            <button
              type="button"
              class="float-right button primary ml-2"
              @click="alleCookiesErlauben()">
              {{ messages['fh.gdpr.alleCookiesErlauben'] }}
            </button>

            <button
              type="button"
              class="float-right button secondary ml-2"
              @click="speichern()">
              {{ messages['default.button.save.label'] }}
            </button>

          </div>
        </template>

      </b-modal>

    </div>

</template>
<script>

  import axios from 'axios'
  axios.defaults.withCredentials = true
  import { EventBus } from '../../event-bus'

  let vmvue = null
  export default {

    name: 'gdpr',
    data () {
      return {
        /** wird auf true gesetzt, wenn bereits das Cookie "CookieConsent" vorhanden ist */
        isGdprSet: false,
        messages: null,
        /** zeige GDPR Modal */
        modalShow: false,
        /** zeige Einstellungen */
        collapseVisible: false,
        erforderlich: true,
        /** Checkbox statistik */
        statistik: false,
      }
    },
    mounted: function () {

      vmvue = this
      this.init()
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang)
      })

    },
    methods: {

      setCookie: function (value) {

        const date = new Date()
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000)) // Tage in Millisekunden umrechnen
        const expires = 'expires=' + date.toUTCString()
        document.cookie = `CookieConsent=${value}; ${expires}; path=/`

      },
      /**
       * setzt das Cookie mit Wert mandatory|statistic
       */
      alleCookiesErlauben: function () {

        console.log('Alle Cookies erlauben')
        this.modalShow = false
        this.setCookie('mandatory|statistic')
        // Google Tag Manager Script laden
        this.appendGoogleTagManager()

      },
      speichern: function () {

        this.isGdprSet = true
        let value = 'mandatory'
        if (this.statistik) {
          // Google Tag Manager Script laden
          this.appendGoogleTagManager()
          value += '|statistic'
        }
        this.setCookie(value)

      },
      init: function () {

        // prüfe ob das Cookie bereits gesetzt ist
        this.checkCookieConsent()

      },
      /** das Cookie "CookieConsent" muss vorhanden sein und statitic gesetzt sein */
      checkCookieConsent: function () {

        // Cookies als String auslesen
        const cookies = document.cookie

        // Nach dem gewünschten Cookie "CookieConsent" suchen
        const cookieConsent = cookies
          .split('; ')
          .find(cookie => cookie.startsWith('CookieConsent='))

        if (cookieConsent) {

          // Den Wert des Cookies extrahieren
          const value = cookieConsent.split('=')[1]

          // Prüfen, ob der Wert 'statistic' enthält
          if (value.includes('statistic')) {

            console.log('Statistiken sind erlaubt.')

            // Google Tag Manager Script laden
            this.appendGoogleTagManager()

          } else {
            console.log('Statistiken sind nicht erlaubt.')
          }

          // Cookie wurde gefunden
          this.isGdprSet = true

        } else {

          // Cookie wurde nicht gefunden
          console.log('Das Cookie "CookieConsent" ist nicht gesetzt.')
          this.isGdprSet = false
          // Übersetzungen laden
          this.fetchMessages(null,function() {
            // Modal anzeigen
            console.log('show modal')
            vmvue.modalShow = true
          })

        }

      },
      /**
       * Dynamisch das Google Tag Manager Script einfügen
       */
      appendGoogleTagManager: function () {

        // GTM Script direkt einfügen
        const existingScript = document.querySelector('script[src="https://www.googletagmanager.com/gtm.js?id=GTM-WHW8V9Z"]');
        const existingIframe = document.querySelector('iframe[src="https://www.googletagmanager.com/ns.html?id=GTM-WHW8V9Z"]');

        if (!existingScript) {
          const script = document.createElement('script');
          script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                           })(window,document,'script','dataLayer','GTM-WHW8V9Z');`
          document.head.appendChild(script);
        }

        if (!existingIframe) {
          const noscript = document.createElement('noscript');
          noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WHW8V9Z"
                              height="0"
                              width="0"
                              style="display:none;visibility:hidden">
                            </iframe>`;
          document.body.appendChild(noscript);
        }

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function(language, callback) {

        const messages = [
          'fh.gdpr.headline',
          'fh.gdpr.body',
          'fh.gdpr.einstellungen',
          'fh.gdpr.alleCookiesErlauben',
          'fh.gdpr.erforderlich',
          'fh.gdpr.erforderlich.desc',
          'fh.gdpr.name',
          'fh.gdpr.zweck',
          'fh.gdpr.ablauf',
          'fh.gdpr.typ',
          'fh.gdpr.anbieter',
          'fh.gdpr.cookieConsent',
          'fh.gdpr.cookieConsent.desc',
          'fh.gdpr.ablauf1',
          'fh.gdpr.typ.html',
          'fh.gdpr.anbieter.website',
          'fh.gdpr.webstatistiken',
          'fh.gdpr.webstatistiken.desc',
          'fh.gdpr.ga.zweck',
          'fh.gdpr.ga.ablauf',
          'fh.gdpr.ga.anbieter',
          'fh.gdpr.gid.ablauf',
          'fh.gdpr.dc_gtm.zweck',
          'fh.gdpr.gali.zweck',
          'fh.gdpr.gali.ablauf',
          'fh.gdpr.meta_pixel.zweck',
          'fh.gdpr.meta_pixel.ablauf',
          'fh.gdpr.meta_pixel.anbieter',
          'default.button.save.label',
        ]
        this.getMessages(language, messages, resp => {

          vmvue.messages = resp
          if (callback && typeof callback === 'function') {
            callback()
          }

        })

      }

    }

  }

</script>
