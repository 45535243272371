<!--
 auflisten aller MMA Files
 /#/ListFiles
-->
<template>

  <div name="listMediaFiles" id="listMediaFiles">

    <h1 class="h4 mt-3">Multimedia Files</h1>

    <b-form @submit="login" v-if="!loggedIn">
      <b-form-input type="password"
                    v-model="password"
                    style="max-width: 300px;"
                    placeholder="Bitte Passwort eingeben"
      />
      <button type="button"
              class="button secondary mt-2"
              v-on:click="login">
        login
      </button>
    </b-form>

    <b-table-simple hover small responsive v-else-if="files && files.length > 0">
      <b-thead>
        <b-tr>
          <b-th colspan="4" class="text-right">
            <b-button variant="danger" size="sm" class="mt-2 mb-2" :disabled="!auswahlSelected" @click="doDelete">
              <b-icon icon="trash"></b-icon> Gewählte löschen
            </b-button>
            <b-button variant="info"
                      v-if="downloadSize < 900"
                      class="ml-1 mr-1 mt-2 mb-2"
                      size="sm"
                      :disabled="!auswahlSelected"
                      @click="doDownload">
              <b-icon icon="box-arrow-down"></b-icon> Gewählte downloaden ({{ downloadSize }} MB)
            </b-button>
            <b-button variant="danger"
                      v-else
                      class="ml-1 mr-1 mt-2 mb-2"
                      size="sm"
                      :disabled="true">
              Die maximale Downloadgröße von 900 MB wurde überschritten ({{ downloadSize }} MB)
            </b-button>
          </b-th>
          <b-th>
            <b-form-checkbox
              id="checkall"
              class="mt-2 mb-2"
              v-model="checkall"
              name="checkall"
              value="true"
              unchecked-value="false">
              alle auswählen
            </b-form-checkbox>
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="file in files" :key="file.filename">
          <b-td>{{ file.time }}</b-td>
          <b-td>{{ file.filename }}</b-td>
          <b-td>{{ file.size }}</b-td>
          <b-td>
            <b-button variant="outline-primary" size="sm" @click="download(file.filename)">
              <b-icon icon="download"></b-icon> download
            </b-button>
          </b-td>
          <b-td>
            <b-form-checkbox
              :id="'checkfile' + file.filename"
              v-model="file.checked"
              name="checkfile"
              value="true"
              unchecked-value="false">
            </b-form-checkbox>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <span v-else-if="files && files.length < 1">
      <b-alert variant="info" show>
        Keine Files vorhanden.
      </b-alert>
    </span>

  </div>

</template>
<script>

  import axios from 'axios'
  import bootbox from 'bootbox'
  import sha256 from 'crypto-js/sha256'

  export default {

    name: 'ListFiles',
    data () {
      return {
        loggedIn: null,
        password: null,
        files: null,
        /** alle Zeilen markieren */
        checkall: 'false'
      }
    },
    computed: {

      auswahlSelected: function () {

        if (this.files && this.files.length > 0) {
          for (let i = 0; i < this.files.length; i++) {
            if (this.files[i].checked == 'true') {
              return true
            }
          }
        }
        return false

      },
      /**
       * berechnet die Filesize die runtergeladen werden soll
       */
      downloadSize: function () {

        let size = 0
        if (this.files && this.files.length > 0) {
          for (let i = 0; i < this.files.length; i++) {
            if (this.files[i].checked == 'true' && this.files[i].sizeValue) {
              console.log('add', this.files[i].sizeValue)
              size += this.files[i].sizeValue
            }
          }
        }
        console.log('check download Size', size)
        return Math.round(size * 100) / 100

      }

    },
    watch: {

      checkall: function (newVal) {

        for (let i = 0; i < this.files.length; i++) {
          this.$set(this.files[i], 'checked', newVal)
        }

      }

    },
    methods: {

      /**
       * ausgewählte Files runterladen
       */
      doDownload: function () {

        let selected = []
        for (let i = 0; i < this.files.length; i++) {
          if (this.files[i].checked == 'true') {
            selected.push(this.files[i].filename)
          }
        }

        if (!selected || selected.length < 1) {

          bootbox.alert('Sie müssen eine Auswahl von mind. 1 Zeile treffen um die Files downloaden zu können.')
          return false

        }

        const hash = sha256(this.password)
        const url = process.env.VUE_APP_SERVER_URL +
          '/file/downloadMMAFiles?filenames=' + selected.join(',') +
          '&passwd=' + hash;

        window.open(url, 'blank');

      },
      doDelete: function () {

        if (this.files && this.files.length > 0) {

          let selected = []
          for (let i = 0; i < this.files.length; i++) {
            if (this.files[i].checked == 'true') {
              selected.push(this.files[i].filename)
            }
          }

          if (!selected || selected.length < 1) {

            bootbox.alert('Sie müssen eine Auswahl von mind. 1 Zeile treffen um die Files löschen zu können.')
            return false

          }

          const that = this
          const msg = 'Sind Sie sicher, dass sie die gewählten löschen wollen?'
          bootbox.confirm (
            {
              message: msg,
              buttons: {
                confirm: {
                  label: 'Ja',
                    className: 'btn-success'
                },
                cancel: {
                  label: 'Abbrechen',
                    className: 'btn-danger'
                }
              },
              callback: async result =>
              {

              if (result) {

                console.log('lösche Files', selected)

                let params = {
                  passwd: this.password
                }
                const url = process.env.VUE_APP_SERVER_URL + '/file/deleteFiles';
                // jeden File einzeln löschen:
                for (let i = 0; i < selected.length; i++) {

                  params.filename = selected[i]
                  await axios({
                    method: 'post',
                    url: url,
                    params: params,
                  }).then(() => {

                    let newlist = []
                    for (let j = 0; j < that.files.length; j++) {
                      if (that.files[j].filename != selected[i]) {
                        newlist.push(that.files[j])
                      }
                    }
                    that.files = newlist

                  })

                }

              } // end if

            } // callback

          })

        }

      },
      download: function (filename) {

        var hash = sha256(this.password);
        filename = encodeURI(filename);
        const url = process.env.VUE_APP_SERVER_URL +
                    '/file/downloadMMAFiles?filename=' + filename +
                    '&passwd=' + hash;

        window.open(url, 'blank');

      },
      /** Passwort prüfen */
      login: function () {

        const url = process.env.VUE_APP_SERVER_URL + '/file/checkListFilesPasswd';
        var params = {
          passwd: this.password
        }
        axios.get(url, {
          params: params
        }).then( response => {

          if (response.data.success) {

            // hole die Files
            this.loggedIn = true;
            this.getFiles();

          } else {

            bootbox.alert("Das Passwort ist nicht korrekt.");

          }

        })

      },
      /** holt alle files */
      getFiles: function () {

        this.showSpinner();
        const url = process.env.VUE_APP_SERVER_URL + '/file/listMMAFiles';
        var params = {
          passwd: this.password
        }
        axios.get(url, {
          params: params
        }).then( response => {

          this.hideSpinner();
          this.files = response.data;

        })

      }

    }

  }
</script>
<style scoped>
  .modal .close {
    width: 56px;
    height: 56px;
  }
</style>
