<template>

  <div id="bellWrapper"
       v-b-tooltip.hover
       v-if="showIt && $store.getters.user && $store.getters.user.role == 'ROLE_USER'"
       :title="messages['fh.messages.openMessages']">

    <router-link to="/messages"
                 href="#">
        <!-- ungelesene Nachrichten sind vorhanden -->
        <b-icon icon="bell-fill"
                v-if="anzahl"
                id="bell-icon"
                class="rounded-circle bg-danger p-2"
                variant="light"
                animation="throb"
        />
        <!-- keine ungelesenen Nachrichten vorhanden -->
        <b-icon icon="bell-fill"
              v-else
              id="bell-icon-green"
              class="rounded-circle bg-info p-2"
              variant="light"
        />
    </router-link>

  </div>

</template>

<script>

  import axios from 'axios';
  import { EventBus } from '../../event-bus'
  axios.defaults.withCredentials = true;

  export default {

    name: 'show-bell',
    data () {
      return {
        /** Anzahl der Nachrichten die noch ungelesen sind */
        anzahl: 0,
        messages: null,
      }
    },
    mounted: function () {

      // Listen for the set-language event on EventBus
      EventBus.$on('set-user', user => {
        if (user) {
          this.init();
        } else {
          this.anzahl = 0;
        }
      });
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    computed: {
      currentRouteName: function () {
        return this.$route.name;
      },
      showIt: function () {
        if (!this.messages) return false;
        if (
          this.currentRouteName !== 'Messages' &&
          this.currentRouteName !== 'Login' &&
          this.currentRouteName !== 'Register'
        ) {
          return true;
        }
        return false;
      }
    },
    methods: {

      /** prüfe ob eine Nachricht vorhanden ist */
      init: function () {

        this.anzahl = 0;
        var url  = process.env.VUE_APP_SERVER_URL + '/messages/hasUserUnreadMessages';
        var that = this;
        var params = {}
        axios.get(url, {
          params: params,
          withCredentials: true
        }).then(function (response) {

            if (response.data && response.data.anzahl) {
              that.anzahl = response.data.anzahl;
            } else {
              console.log('Keine Nachrichten vorhanden ' + response.data.anzahl);
            }
            that.fetchMessages();

        });

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function(language) {

        var messages = [
          'fh.messages.openMessages',
          'fh.messages.openMessages.1',
          'fh.messages.openMessages.0'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {

          that.messages = resp;

          // Anzahl setzen
          if (that.anzahl > 1) {
            that.messages['fh.messages.openMessages'] = that.messages['fh.messages.openMessages'].replace('{0}', that.anzahl);
          } else if (that.anzahl === 1) {
            that.messages['fh.messages.openMessages'] = that.messages['fh.messages.openMessages.1'];
          } else {
            that.messages['fh.messages.openMessages'] = that.messages['fh.messages.openMessages.0'];
          }

        });

      }

    }

  }

</script>
<style scoped>

  #bellWrapper {
    position: fixed;
    z-index: 1000;
    bottom: 2em;
    right: 2em;
    width: 50px;
    height: 50px;
  }
  #bell-icon,
  #bell-icon-green {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

</style>
