import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/login/Login'
import UserData from '@/components/UserData'
import Studiengang from '@/components/Studiengang'
import Umfrage from '@/components/Umfrage'
import Upload from '@/components/Upload'
import Datenschutz from '@/components/Datenschutz'
import Reife from '@/components/Reife'
import ForgotPassword from '@/components/login/ForgotPassword'
import NewPassword from '@/components/login/NewPassword'
import Register from '@/components/Register'
import Bewerbung from '@/components/Bewerbung'
import Vertragszustimmung from '@/components/Vertragszustimmung'
import Messages from '@/components/Messages'
import UploadMedien from '@/components/UploadMedien'
import ListFiles from '@/components/ListFiles.vue'
import FhUser from "@/components/FhUser.vue";
import Studenten from "@/components/Studenten.vue";
import HobexCallback from "@/components/HobexCallback.vue";

Vue.use(Router)

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((error) => {
    console.log(error)
  })
}

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login,
      props: true
    },
    {
      path: '/userData/:isVorbildungEuNotValid',
      name: 'UserData',
      component: UserData
    },
    {
      path: '/userData',
      name: 'UserData',
      component: UserData
    },
    {
      path: '/bewerbung',
      name: 'Bewerbung',
      component: Bewerbung
    },
    {
      path: '/studiengang',
      name: 'Studiengang',
      component: Studiengang
    },
    {
      path: '/upload',
      name: 'Upload',
      component: Upload
    },
    {
      path: '/uploadMedien',
      name: 'UploadMedien',
      component: UploadMedien
    },
    {
      path: '/datenschutz',
      name: 'Datenschutz',
      component: Datenschutz
    },
    {
      path: '/hobexcallback',
      name: 'HobexCallback',
      component: HobexCallback
    },
    {
      path: '/umfrage',
      name: 'Umfrage',
      component: Umfrage
    },
    {
      path: '/reife',
      name: 'Reife',
      component: Reife
    },
    {
      path: '/forgotPassword',
      name: 'ForgotPassword',
      component: ForgotPassword
    },
    {
      path: '/newPassword/:token',
      name: 'NewPassword',
      component: NewPassword
    },
    {
      path: '/register/:token',
      name: 'Register',
      component: Register
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/vertragszustimmung',
      name: 'Vertragszustimmung',
      component: Vertragszustimmung
    },
    {
      path: '/messages',
      name: 'Messages',
      component: Messages
    },
    /**
     * auflisten aller MMA Files
     * /#/ListFiles
     */
    {
      path: '/listFiles',
      name: 'ListFiles',
      component: ListFiles
    },
    /** Liste mit FH Usern */
    {
      path: '/fhUser',
      name: 'FhUser',
      component: FhUser
    },
    /** Liste mit Studenten */
    {
      path: '/studenten',
      name: 'Studenten',
      component: Studenten
    }

  ]
});
