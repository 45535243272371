<template>

  <div class="selectLandWrapper" v-show="laender">

    <select v-model="selected"
            class="dropdown bootstrap-select form-control"
            v-bind:class="{ 'is-invalid': !isValid }"
            :disabled="readonly">
      <option
        v-for="land in laender"
        v-bind:value="land"
        v-bind:key="land.id"
        name="land">
        {{ land.name }}
      </option>
    </select>
    <div class="invalid-feedback" v-if="invalidMessage">
      {{invalidMessage}}
    </div>

  </div>

</template>

<script>

  import axios from 'axios';
  import { EventBus } from '../../event-bus';
  axios.defaults.withCredentials = true;

  export default {

    name: 'select-land',
    props: {
      land: {
        type: Object,
        required: false
      },
      invalidMessage: {
        type: String,
        required: false
      },
      readonly: null
    },
    data () {
      return {
        /** mögliche Länder */
        laender: null,
        /** vom User ausgewähltes Land */
        selected: null
      }
    },
    mounted: function () {

      this.init();
      if (this.land) {
        this.selected = this.land;
      }
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        console.log('set-language: ' + lang)
        this.init();
      });

    },
    watch: {

      /** Land wurde von aussen übergeben */
      land: function (val) {
        this.selected = val;
      },
      /** Land wurde vom Benutzer ausgewählt */
      selected: function (val) {
        this.emitLand(val);
      }

    },
    computed: {

      /** prüft ob der Benutzername eingegeben wurde */
      isValid: function () {

        if (!this.selected || this.selected.id == null) {
          return false;
        }
        return true;

      }

    },
    methods: {

      init: function () {

        var that = this;

        // hole alle Länder:
        var url  = process.env.VUE_APP_SERVER_URL + '/land';
        var params = {
          bewerbung_id: this.getBewerbung()
        }
        axios.get(url, {
          params: params,
          withCredentials: true
        })
        .then(function (response) {

          that.laender = response.data;
          // Land vorauswählen falls gesetzt und Österreich als erstes setzen:
          var code = that.land && that.land.iso3166 ? that.land.iso3166 : 'AT';
          var sortedlist = [];
          var at = null;
          for (var i = 0; i < that.laender.length; i++) {
            if (that.laender[i].iso3166 === code) {
              that.selected = that.laender[i];
            }
            if (that.laender[i].iso3166 === 'A') {
              at = that.laender[i];
            } else {
              sortedlist.push(that.laender[i]);
            }
          }
          if (at) sortedlist.unshift(at);
          that.laender = sortedlist;

        });

      },
      /** emit Land */
      emitLand: function (val) {

        if (val) this.selected = val;
        this.$emit('set-land', this.selected);

      }

    }

  }

</script>
